import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { General } from './aplicativo/model/General';
import { InfoConsultada } from './shared/InfoConsultada.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor (private ruta:Router) {}

  // Recupero la información del programa luego de una recarga
  @HostListener(
    'window:load',
    ['$event']
  )
  ejecuteseDespuesDeRefrescar(event:Event) {
    if(sessionStorage.getItem('info')) {
      InfoConsultada.getInstancia().isReady = false;
      InfoConsultada.getInstancia().importProgramState(sessionStorage.getItem('info'))
        .then( mensaje => InfoConsultada.getInstancia().isReady = true );
    }
    else {
      InfoConsultada.getInstancia().clear();
      this.redirigirRuta('sencilla', InfoConsultada.getInstancia().getInfo('rol')+'/login', null);
    }
  }


  title = 'Fecoomeva';

  redirigirRuta(tipoRuta, ruta, indicativo) {
    switch (tipoRuta) {
      case 'sencilla':
        this.ruta.navigate([ruta]);
        break;
      case 'indicador':
        this.ruta.navigate([ruta, indicativo]);
        break;
    }
  }
}
